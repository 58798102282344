.container {
  background: rgb(237, 241, 247);
  padding: 24px;

  .md-mt-24 {
    @media screen and (max-width: 990px) {
      margin-top: 24px;
    }
  }

  @media screen and (max-width: 500px) {
    padding: 12px;
  }

  .grid {
    margin-top: 24px;
  }

  color: #80888f;

  .card {
    .header {
      padding: 24px;
      height: 72px;
      color: #fff;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;

      .title {
        font-weight: 600;
        font-size: 20px;

        &.big {
          width: 100%;
          font-size: 32px;
          text-align: center;
        }
      }

      button,
      .pi.white {
        color: #fff !important;
      }
    }

    .content {
      padding: 24px;
      background: #fff;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;

      .area {
        color: #80888f;

        .title {
          &.light {
            color: #ced4da;
          }

          &.sm-mt-24 {
            @media screen and (max-width: 530px) {
              margin-top: 24px;
            }
          }
        }

        &.bg {
          background: #f8f9fb;
          padding: 18px;
          border-radius: 10px;
        }
      }
    }

    &.payment-card {
      .money {
        @media screen and (max-width: 1450px) {
          font-size: 40px !important;
          margin-top: 2px !important;
        }

        @media screen and (max-width: 320px) {
          font-size: 34px !important;
          margin-top: 0 !important;
        }
      }
    }

    @media screen and (max-width: 530px) {
      .xs-col-12 {
        flex: 0 0 auto;
        width: 100%;

        &.mt-24 {
          margin-top: 12px;
          padding: 0;
        }
      }

      .xs-col-6 {
        flex: 0 0 auto;
        width: 50%;
        padding: 0.5rem !important;

        &#first {
          padding-top: 0 !important;
        }
      }
    }
  }
}
