.progress-page {
  .prev-points {
    .points {
      @media screen and (max-width: 992px) {
        margin-top: 12px;
        overflow-x: scroll;
        max-width: 100%;
      }
    }

    .point {
      &:last-of-type {
        padding-right: 0;
      }

      .area {
        background: #e1f0f0 !important;
      }
    }

    @media screen and (max-width: 992px) {
      flex-direction: column;

      .point {
        min-width: 165px;

        &:first-of-type {
          padding-left: 0 !important;
        }

        &:last-of-type {
          padding-right: 0 !important;
        }
      }
    }
  }

  .cur-point {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    @media screen and (max-width: 500px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .title {
      color: #fff;
      font-size: 20px;
      line-height: 28px;
      text-transform: uppercase;

      @media screen and (max-width: 1600px) {
        line-height: initial;
      }

      @media screen and (max-width: 1100px) {
        font-size: 18px;
      }
    }

    .h1 {
      color: #fff;
      font-size: 96px;
      margin-left: 48px;

      @media screen and (max-width: 1600px) {
        margin-left: 18px;
        font-size: 84px;
      }

      @media screen and (max-width: 1100px) {
        font-size: 76px;
      }

      @media screen and (max-width: 500px) {
        margin-left: 0;
      }
    }
  }

  .marks {
    overflow-x: scroll;

    .mark {
      min-width: 290px;

      &:first-of-type {
        padding-left: 0 !important;
      }

      &:last-of-type {
        padding-right: 0 !important;
      }
    }
  }

  .filter-container {
    @media screen and (max-width: 700px) {
      flex-direction: column;

      .p-dropdown,
      .p-multiselect {
        width: 100%;
        margin-left: 0 !important;
        margin-top: 12px;
      }
    }
  }

  .types {
    .type {
      .type-icon {
        margin-top: 3.5px;
        width: 12px;
        height: 12px;
        margin-right: 12px;
        border-radius: 50px;
      }
    }
  }
}
