.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.main {
    margin-top: 16px;

    .progress {
        height: 4px;
        margin-top: 8px;
    }
}

.footer {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow-x: scroll;
    min-width: 100%;

    .statistic {
        width: 100%;
        background: #f8faff;
        padding: 9px 16px;
        margin: 0 8px;

        &:first-of-type {
            margin: 0;
        }

        &:last-of-type {
            margin: 0;
        }

        .textPrime {
            margin-top: 8px;
        }
    }
}
