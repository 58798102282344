.homework-page {
    --sidebar-width: 260px;
    --outer-padding: 24px;

    .content {
        width: calc(100vw - var(--sidebar-width) - var(--outer-padding) * 2);
    }

    .statistic {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 300px;
        min-width: 250px;
        border: 2px solid #d97706;
        background: transparent;
        transition:
            background-color 0.2s,
            color 0.2s,
            border-color 0.2s,
            box-shadow 0.2s;

        &.selected {
            background: #d97706 !important;
            * {
                color: #fff !important;
            }
        }

        .title {
            color: #d97706;
        }

        .h1 {
            color: #d97706;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .task {
        .task-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-content: stretch;
            height: 100%;
        }

        .body {
            flex: 1 1 auto;
        }

        .footer {
            flex: 0 1 auto;
        }

        .name {
            font-size: 28px;
            font-weight: 700;
            color: #495057;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }

        .passed {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.2);
                border-radius: 10px;
                pointer-events: none;
            }
        }
    }

    @media screen and (max-width: 1350px) {
        --sidebar-width: 0;
    }

    @media screen and (max-width: 500px) {
        .card .header .title {
            font-size: 26px !important;
        }

        .task .name {
            font-size: 24px !important;
        }

        .filter-container {
            flex-direction: column;

            .p-dropdown {
                width: 100%;

                &:last-of-type {
                    margin-left: 0;
                    margin-top: 8px;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .p-dialog {
        width: 90vw !important;
    }
}

@media screen and (max-width: 550px) {
    .p-fileupload-buttonbar {
        flex-direction: column;
        align-items: initial !important;

        button {
            margin-top: 8px;
        }
    }
}
