.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow-x: auto;

    button {
        overflow: visible;
    }
}

.infoCard {
    margin-right: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .divider {
        height: 100%;
        width: 1px;
        background: #eaecef;
        margin-left: 24px;
    }
}
