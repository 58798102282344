.table {
    margin-top: 24px;

    th {
        &:first-of-type {
            border-top-left-radius: 12px;
        }

        &:last-of-type {
            border-top-right-radius: 12px;
        }
    }
}
