.wrapper {
  align-items: center;
  * {
    font-size: 16px;
  }
}
.indicator {
  height: 1em;
  width: 1em;
  border-radius: 100%;
  background: #1d4ed8;
  &.danger {
    background: #e52a23;
  }
  &.info {
    background: #d97706;
  }
}