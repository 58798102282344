.notifications {
  display : flex;
  flex-direction: column;
  overflow: auto;
  gap : 24px;
  overflow-x : hidden;
  max-height: 350px;
}
.notificationGroup {
  display: flex;
  gap : 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding : 0 24px;
  text-transform: uppercase;
  position : relative;
  color: #CED4DA;
  &::after {
    position : relative;
    display : block;
    top : 0.9em;
    width : 100%;
    content: '';
    background: #CED4DA;
    height : 1px
  }
}
.buttons {
  margin-top : 24px;
  gap: 12px;
  display: flex;
  padding: 0 24px;
  flex-direction: column;
  align-items: stretch;
}
.notification {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-weight: 400;
  font-size: 10px;
  padding : 24px;
  background: #FFFFFF;
  &.striped {
    background: #f5f5f5;
  }
  .title {
    color: #16A34A;
    line-height: 12px;
  }
  .content {
    line-height: 15px;
    font-size: 12px;
    color: #80888F;
  }
  .footer {
    color: #C1C9D1;
  }
  .row {
    display: flex;
    justify-content: space-between;
    gap: 12px;
  }
}