.container {
    width: 100%;
    height: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #ffffff;
    margin-top: 24px;
}

.line {
    height: 4px;
    background: #3b82f6;
    animation: fill-bar 1.2s ease-in-out;
    animation-fill-mode: both;
}

.circle {
    min-width: 20px;
    min-height: 20px;
    border-radius: 50%;
    background: #3b82f6;
    position: relative;

    &.outlined {
        margin-left: auto;
        background: #ffffff;
        border: 2.5px solid #3b82f6;
    }

    span {
        width: 50px;
        left: -15px;
        text-align: center;
        position: absolute;
        color: #80888f;
        top: 24px;
    }
}

@keyframes fill-bar {
    0% {
        width: 0;
    }

    100% {
        width: var(--to-width);
    }
}
