.header {
    background: #44afaf;
}

.lessonTooltip {
    & .p-tooltip-text {
        background: #495057 !important;
    }
}

.label {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #80888f;
}

.classWrapper {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    width: 100%;
}

.formItem {
    display: flex;
    gap: 12px;
    flex-direction: column;
    align-items: flex-start;

    &:not(&:first-child) {
        margin-top: 24px;
    }
}

.tableWrapper {
    margin-top: 48px;

    & .table {
        & thead {
            & tr {
                & th {
                    color: white;
                    border-radius: 10px 10px 0 0;
                    border-left: 2px solid white;
                    border-right: 2px solid white;

                    &:first-child {
                        border-left: none;
                    }

                    &:last-child {
                        border-right: none;
                    }

                    &:nth-child(odd) {
                        background: #44afaf;
                    }

                    &:nth-child(even) {
                        background: #52bcbc;
                    }
                }
            }
        }

        & tbody {
            & tr {
                & td {
                    border: none;
                    height: 100px;
                    padding: 10px;

                    & textarea {
                        resize: none;
                    }
                }

                &:nth-child(odd) {
                    background: #f2f6fe;
                }

                &:nth-child(even) {
                    background: #ecf0f8;
                }
            }
        }
    }
}

.rating {
    display: flex;
    gap: 12px;
    .ratingItem {
        cursor: pointer;
    }
}

.absence {
    :global(.p-dropdown-label) {
        color: #89b4fa;
    }
    color: #89b4fa !important;
}
.noMark {
    :global(.p-dropdown-label) {
        color: #80888f;
    }
    color: #80888f !important;
}
.bad {
    :global(.p-dropdown-label) {
        color: #cf4e4e;
    }
    color: #cf4e4e !important;
}
.ok {
    :global(.p-dropdown-label) {
        color: #d97706;
    }
    color: #d97706 !important;
}
.good {
    :global(.p-dropdown-label) {
        color: #16a34a;
    }
    color: #16a34a !important;
}
$border-radius-desktop: 36px;
$border-radius-mobile: 25px;
.modal {
    overflow: hidden;
    border-radius: 36px !important;
    @media (max-width: 500px) {
        border-radius: $border-radius-mobile !important;
    }
    &:global(.p-dialog .p-dialog-header) {
        border-top-left-radius: $border-radius-desktop;
        border-top-right-radius: $border-radius-desktop;
        padding: 48px 48px 24px 48px;
        @media (max-width: 500px) {
            border-top-left-radius: $border-radius-mobile;
            border-top-right-radius: $border-radius-mobile;
            padding: 1rem;
        }
        color: #44afaf;
    }
    :global(.p-dialog-content) {
        border-bottom-left-radius: $border-radius-desktop !important;
        border-bottom-right-radius: $border-radius-desktop !important;
        padding: 0 48px 48px 48px !important;
        @media (max-width: 500px) {
            border-bottom-left-radius: $border-radius-mobile !important;
            border-bottom-right-radius: $border-radius-mobile !important;
            padding: 0 1rem 1rem 1rem !important;
        }
    }
    width: 960px;
    form {
        margin-top: 30px;
        textarea {
            resize: none;
            min-height: 180px;
        }
        :global(.field) {
            margin-bottom: 1.75rem;
        }
    }
}

.nearestLessonsSlider {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    overflow-x: scroll;

    .nearestLesson {
        min-width: max(540px, 50%);
        margin: 0 8px;

        &.first {
            margin-left: 0;
        }
        &.last {
            margin-right: 0;
        }

        &.many {
            min-width: max(540px, 33%);
        }
    }
}
