.materials-page {
  .area {
    position: relative;
    .source-label {
      background: rgba(255, 255, 255, 0.8);
      width: 48px;
      height: 48px;
      font-size: 20px;
      font-weight: 700;
      border-radius: 0px 0px 5px 5px;
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .md-flex-column {
      @media screen and (max-width: 700px) {
        flex-direction: column;
        align-items: end;

        .source-label {
          align-self: flex-start !important;
        }

        .preview {
          margin-top: 12px;
        }
      }
    }
  }

  .area.material {
    .body {
      flex: 1 1 auto;
      word-break: break-word;
    }

    .footer {
      flex: 0 1 auto;
    }

    @media screen and (max-width: 1470px) {
      .preview-container {
        flex-direction: column;
        align-items: end;

        small {
          margin-top: 0;
        }

        .source-label {
          align-self: flex-start !important;
        }

        .preview {
          margin-top: 12px;
        }
      }
    }
  }

  .materials-bg {
    color: #fff !important;

    .text.bold {
      font-size: 24px;
      line-height: 30px;
      max-width: 60%;
    }

    .source-label {
      background: rgba(255, 255, 255, 0.8);
    }
  }

  .md-mt-24 {
    @media screen and (max-width: 1100px) {
      margin-top: 24px;
    }

    @media screen and (max-width: 700px) {
      .area {
        padding-top: 0 !important;
      }
    }
  }

  .last-materials {
    .created-date {
      color: #ab71c7;
    }

    .bg-pink {
      background: #fbf5fd !important;
    }

    @media screen and (max-width: 1100px) {
      flex-direction: column !important;

      .first {
        padding-left: 0 !important;
      }

      .last {
        width: 100%;
        padding-right: 0;
      }
    }
  }

  .p-button {
    &.ml {
      margin-left: 24px;

      @media screen and (max-width: 620px) {
        margin-left: 0;
      }
    }

    &:focus {
      &.id-1 {
        box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px rgba(217, 119, 6, 0.5),
          0 1px 2px 0 black !important;
      }

      &.id-2 {
        box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px rgba(22, 163, 74, 0.5),
          0 1px 2px 0 black !important;
      }

      &.id-3 {
        box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px rgba(141, 68, 175, 0.5),
          0 1px 2px 0 black !important;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .card .header .title {
      font-size: 24px !important;
    }

    .last-materials .col-6 .bold {
      max-width: 100%;
      font-size: 20px !important;
      line-height: initial;
    }
  }

  .source-filter {
    @media screen and (max-width: 620px) {
      flex-direction: column;
      margin-top: 12px;

      .pair {
        display: flex;
        justify-content: space-between;

        button {
          width: 45%;

          &:first-of-type {
            margin-left: 0 !important;
          }
        }

        &:last-of-type {
          margin-top: 8px;
        }
      }
    }
  }

  .filter-container {
    @media screen and (max-width: 800px) {
      flex-direction: column;

      .mt {
        margin-left: 0 !important;
        margin-top: 12px;
      }

      .p-input-icon-right,
      .p-dropdown,
      input {
        width: 100% !important;
        margin-left: 0 !important;
      }
    }
  }
}
