.card {
    margin-top: 24px;
}

.timetable {
    padding: 48px 24px 24px 24px;
    display: flex;
    flex-direction: column;

    .header {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #495057;
        @media (max-width: 500px) {
            text-align: center;
        }
    }

    .dates {
        width: fit-content;
        background: #F2F6FE;
        border-radius: 54px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
        height: 45px;

        :global(.p-inputtext) {
            cursor: pointer;
            background: transparent;
            color: #495057;
            width: 300px;
            text-align: center;
            border: none;
        }

        :global(.p-inputtext:enabled:focus) {
            background: none;
            box-shadow: none;
        }

        :global(.p-button) {
            height: 28px !important;
            width: 28px !important;
        }

        @media (max-width: 500px) {
            flex-direction: column;
            :global(.p-button) {
                width: 2rem !important;
                height: 2rem !important;
            }
            gap: 5px;
        }

        .datesText {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 5px;
        }

        .today {
            text-transform: capitalize;
        }

        .controls {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 12px;
        }
    }

    .wrapper {
        @media (max-width: 1100px) {
            .dates {
                flex-basis: 100%;
            }
            > * {
                flex: 1;
            }
        }
    }

    .tableWrapper {
        overflow: auto;
        position: relative;
        margin-top: 24px;

        &.loading {
            overflow: hidden;
        }

        .overlay {
            height: 100%;
            width: 100%;
            background: rgba(187, 189, 191, 0.3);
            position: absolute;
            z-index: 100;

            :global(.loader-container) {
                height: 100%;
            }
        }

        .table {
            background: #f2f6fe;
            border-radius: 10px 0 0 10px;
            border-collapse: separate;
            table-layout: auto;
            width: 100%;
            border-spacing: 0;

            thead {
                tr {
                    th {
                        position: sticky;
                        top: 0;
                        z-index: 1;
                        padding: 10px 15px;
                        min-width: 209px;
                        background: #f2f6fe;
                        text-transform: capitalize;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: #80888f;

                        &:first-child {
                            border-top-left-radius: 10px;
                            font-weight: 400;
                        }
                    }

                    th:first-child {
                        position: sticky;
                        left: 0;
                        z-index: 2;
                    }
                }
            }

            tbody {
                tr {
                    th {
                        position: sticky;
                        left: 0;
                        z-index: 1;
                        font-size: 14px;
                    }

                    &:last-child {
                        th {
                            border-bottom-left-radius: 10px;
                        }
                    }

                    td {
                        height: 100px;

                        &:not(&:first-child) {
                            padding: 0;
                        }

                        &:first-child {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            color: #80888f;
                            white-space: nowrap;
                        }
                    }

                    .today {
                        background: #dce9ff;
                    }

                    &:nth-child(odd) {
                        td,
                        th {
                            background: #ecf0f8;
                        }
                    }

                    &:nth-child(even) {
                        td,
                        th {
                            background: #f2f6fe;
                        }
                    }
                }
            }

            td {
                border-right: 1px solid #ecf0f8;
            }
        }

        .cell {
            align-items: stretch;
            grid-template-columns: repeat(2, minmax(min-content, 50%));
            justify-content: center;
            display: grid;
            min-height: 100%;
            height: fit-content;
            width: 100%;
            background: repeating-linear-gradient(
                            120deg,
                            #FCFDFF,
                            #FCFDFF 10px,
                            #f8f9fa 10px,
                            #f8f9fa 17px
            );
            flex-wrap: wrap;
        }

        .empty {
            grid-template-columns: auto;
        }

        .lesson {
            white-space: nowrap;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 6px;
            padding: 5px 5px 5px 10px;
            background: #fcfdff;
            font-size: 9px;
            line-height: 11px;
            color: #000000;

            &.full {
                grid-column: 1/3;
            }
        }

        .lessonAdd {
            align-items: center;
            justify-content: center;
            background: repeating-linear-gradient(
                            120deg,
                            #FCFDFF,
                            #FCFDFF 10px,
                            #f8f9fa 10px,
                            #f8f9fa 17px
            );

            &.full {
                min-height: 50px;
                grid-column: 1/3;
            }
        }

        .lesson, .lessonAdd, .empty {
            &:hover {
                border: 1px solid #3B82F6;
            }
        }

        .empty, .lessonAdd {
            align-items: center;
            border: 1px solid #ecf0f8;

            &:hover {
                color: #3B82F6;
            }
        }

        .lesson, .lessonAdd {
            display: flex;
            border: 1px solid #ecf0f8;
        }
    }
}

.tooltip {
    background: #FFFFFF !important;

    :global(.p-tooltip-text) {
        width: 250px;
        padding: 15px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        background: #FFFFFF !important;
        color: #000000 !important;
        border-radius: 0 10px 10px 10px;
        font-weight: 400;
        font-size: 11px;
        line-height: 20px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    :global(.p-tooltip-arrow) {
        display: none;
    }

    .row {
        display: flex;
        gap: 6px;
        align-items: center;
    }

    .label {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #CED4DA;
    }
}

.active {
    color: #3B82F6;
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    background: #EFF6FF;
    justify-content: center;
}