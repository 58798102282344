@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

* {
    padding: 0;
    margin: 0;
    outline: 0;
    border: 0;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 16px;
    box-sizing: border-box !important;
}

html,
body {
    width: 100%;
    max-width: 100vw;
    min-height: 100%;
}

body {
    overflow-x: hidden;
}

#root {
    min-height: 100vh;
}

button {
    font-weight: 600 !important;
}

a {
    text-decoration: none;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background: #fff;
}

::-webkit-scrollbar-thumb {
    background: #3b82f6;
    border-radius: 8px;
    border: 4px solid #fff;
}

@import '../node_modules/primereact/resources/themes/lara-light-blue/theme.css';
@import '../node_modules/primereact/resources/primereact.min.css';
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/primeflex/primeflex.min.css';

.h1 {
    margin-top: 12px;
    font-size: 62px;
    line-height: 58px;

    &.dark {
        color: #80888f !important;
    }
}

.h2 {
    font-size: 31px;
    line-height: 31px;
}

.h2,
.h3 {
    margin-left: 10px;
}

.h1,
.h2,
.h3 {
    color: #ced4da;
    font-weight: 700;
    padding-right: 10px;
}

.h1.border,
.h2.border,
.h3.border {
    border-right: 1px solid #ced4da;

    &:last-child {
        border-right: none;
    }
}

small {
    display: block;
    margin-top: 8px;

    &.date {
        color: #c1c9d1;
    }
}

.text {
    margin-top: 12px;
}

.mt-4 {
    margin-top: 4px !important;
}

.mt-6 {
    margin-top: 6px !important;
}

.mt-8 {
    margin-top: 8px !important;
}

.mt-12 {
    margin-top: 12px !important;
}

.mt-24 {
    margin-top: 24px;
}

.gap-24 {
    gap: 24px;
}

.p-24 {
    padding: 24px;
}

.mb-24 {
    margin-bottom: 24px;
}

.mt-48 {
    margin-top: 48px !important;
}

.mt-64 {
    margin-top: 64px !important;
}

.ml-12 {
    margin-left: 12px !important;
}

.ml-24 {
    margin-left: 24px;
}

.ml-48 {
    margin-left: 48px !important;
}

.ml-72 {
    margin-left: 72px !important;
}

.mb-12 {
    margin-bottom: 12px !important;
}

.mb-64 {
    margin-bottom: 64px !important;
}

.mr-24 {
    margin-right: 24px !important;
}

.m-24 {
    margin-left: 24px !important;
    margin-right: 24px !important;
    margin-top: 24px !important;
    margin-bottom: 24px !important;
}

.media-md-mt-24 {
    margin-top: 0 !important;

    @media screen and (max-width: 990px) {
        margin-top: 24px !important;
    }
}

.mt-16 {
    margin-top: 16px;
}

.w-170 {
    width: 170px;
}

.w-300 {
    width: 300px;
}

.w-400 {
    width: 400px;
}

.h-300 {
    height: 300px;
}

.w-half {
    width: 50%;
}

.bold {
    font-weight: 600;
}

.block-ruby {
    display: block ruby;
}

.px-18 {
    padding-left: 18px;
    padding-right: 18px;
}

.min-w-300 {
    min-width: 300px;
}

.p-12 {
    padding: 12px;
}

.pl-4 {
    padding-left: 4px !important;
}

.min-w-fit {
    min-width: fit-content;
}

.pl-05 {
    padding-left: 0.5rem !important;
    padding-right: 0 !important;
}

.pr-05 {
    padding-right: 0.5rem;
    padding-left: 0;
}

.fz-20 {
    font-size: 20px;

    @media screen and (max-width: 540px) {
        font-size: 18px;
    }
}

.fz-12 {
    font-size: 12px;
}

.fz-24 {
    font-size: 24px;
}

.preview {
    img {
        width: 48px;
        height: 48px;
        object-fit: cover;
        border-radius: 10px;
    }
}

.p-button-loading-icon {
    margin-right: 8px;
}

.points-bg {
    background: #44afaf !important;
}

.points-text {
    color: #44afaf !important;
}

.homework-bg {
    background: #d97706 !important;
}

.homework-text {
    color: #d97706 !important;
}

.materials-bg {
    background: #8d44af !important;
}

.materials-text {
    color: #8d44af !important;
}

.timetable-bg {
    background: #3b82f6 !important;
}

.timetable-text {
    color: #3b82f6 !important;
}

.payment-bg {
    background: #16a34a !important;
}

.payment-text {
    color: #16a34a !important;
}

.faq-bg {
    background: #495057 !important;
}

.faq-text {
    color: #495057 !important;
}

.chat-bg {
    background: #c56aa0 !important;
}

.chat-text {
    color: #c56aa0 !important;
}

.danger-bg {
    background: #dc2626 !important;
}

.danger-text {
    color: #dc2626 !important;
}

.text-black {
    color: #000;
}

.profile-bg {
    background: #80888f;
}

.shadow {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.text-success {
    color: #22c55e;
}

.text-info {
    color: #3b82f6;
}

.text-error {
    color: #ef4444;
}

.text-warning {
    color: #f59e0b;
}

.text-overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.h-full-flex {
    flex: 1;
}

.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.1);
}

.p-column-filter-buttonbar {
    gap: 0.5rem;
}

/* NEW DESIGN CLASSES */

.new {
    &-area {
        border-radius: 12px;
        background: #ffffff;
        padding: 24px;
    }

    &-text {
        &-prime {
            font-weight: 500;
            font-size: 14px;
            color: #495057;

            &.new-heading {
                font-size: 24px;
                font-weight: 600;
            }
        }

        &-secondary {
            font-weight: 400;
            font-size: 10px;
            color: #80888f;
        }

        &-orange {
            color: #d97706;
        }

        &-blue {
            color: #3b82f6;
        }
    }
}

.fz-16 {
    font-size: 16px !important;
}

@for $i from 1 through 12 {
    @keyframes fill-#{$i}-bar {
        0% {
            height: 0;
        }

        100% {
            height: var(--to-width-#{$i});
        }
    }

    .progress-#{$i} {
        animation: fill-#{$i}-bar 1.2s ease-in-out;
        animation-fill-mode: both;

        &.current::after {
            animation: fill-#{$i}-bar 1.2s ease-in-out;
            animation-fill-mode: both;
        }
    }
}

.detail-group-student-table {
    .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
        background: #f2f6fe !important;
    }
}
