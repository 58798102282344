.delete-button-container {
  button {
    background: #ef4444;
    border: 1px solid #ef4444;

    &:hover {
      background: #dc2626 !important;
      border-color: #dc2626 !important;
    }

    &:focus {
      box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #f7a2a2, 0 1px 2px 0 black !important;
    }
  }
}
