.modalHeader {
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    color: #495057;

    span {
        font-weight: 600;
        font-size: 24px;
        color: #3b82f6;
    }
}
