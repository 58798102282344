.page {
    margin-top: 24px;
    .pageHeader {
        background: #d97706;
    }
    .pageContent {
        padding: 24px;
    }
    .header {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #80888f;
    }
    .class {
        margin-top: 12px;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #495057;
    }
    .homeWork {
        border-width: 1px;
        border-style: solid;
        padding: 24px;
        background: #f8faff;
        border-radius: 10px;
        line-height: 19px;
        .headerWrapper {
            display: flex;
            justify-content: space-between;
            row-gap: 12px;
        }
        .date {
            color: #c1c9d1;
        }
        .name {
            color: #495057;
            margin-left: 24px;
        }
        .avatar {
            width: 48px;
            height: 48px;
            object-fit: cover;
            border-radius: 100%;
            .img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .content {
            margin-top: 24px;
            display: flex;
            gap: 24px;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        .buttons {
            height: fit-content;
            display: flex;
            gap: 6px;
        }
        &.completed {
            border-color: #16a34a;
            box-shadow: 0px 0px 10px rgba(22, 163, 74, 0.3);
            .header {
                color: #16a34a;
            }
        }
        &.progress {
            border-color: #ced4da;
            .header {
                color: #d97706;
            }
        }
        &.over {
            border-color: #dc2626;
            box-shadow: 0px 0px 10px rgba(220, 38, 38, 0.3);
            .header {
                color: #dc2626;
            }
        }
    }
}
.mark {
    outline: none;
    border: 1px solid #ced4da;
    border-radius: 10px;
    width: 48px;
    height: 48px;
    aspect-ratio: 1;
    background: #ffffff;
    cursor: pointer;
    &.active {
        color: #ffffff;
        &.bad {
            background: #cf4e4e;
        }
        &.ok {
            background: #d97706;
        }
        &.good {
            background: #16a34a;
        }
    }
    &:hover,
    &:focus {
        color: #ffffff;
        &.bad {
            background: #cf4e4e;
        }
        &.ok {
            background: #d97706;
        }
        &.good {
            background: #16a34a;
        }
    }
    &.disabled {
        background: #80888f;
        color: #ffffff;
        pointer-events: none;
    }
}

.modal {
    .fio {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #495057;
        margin-top: 6px;
    }
}
