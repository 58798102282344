.card {
  width: 100%;
}
.content {
  background: #ffffff;
  border-radius: 0 0 10px 10px;
}
.header {
  text-align : center;
  height: 72px;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #FFFFFF;
  background: #3B82F6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0 0;
}