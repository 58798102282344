.sidebar {
  background: #fff;
  min-width: 260px;
  &__indicator {
    position: absolute;
    top: 50%;
    right: 1em;
    transform: translateY(-50%);
    background: linear-gradient(180deg, #F9AA4B -0.02%, #F7931A 99.99%);
    border-radius: 100%;
    color: white;
    aspect-ratio: 1;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-content {
    position: fixed;

    .logo-container {
      margin: 32px 0;

      a {
        display: block;
      }

      .logo {
        img {
          width: 195px;
          height: 72px;
        }
      }
    }

    .nav {
      list-style: none;
      margin-top: 24px;

      &-link {
        height: 55px;
        border-top: 2px solid #f2f6fe;

        .icon {
          margin-left: 14px;
        }

        .text {
          font-size: 16px;
          color: #495057;
          margin-left: 10px;
          margin-top: 0;
        }

        &:hover .text {
          text-decoration: underline;
        }

        &.last {
          border-bottom: 2px solid #f2f6fe;

          button {
            width: 34px;
            height: 34px;
          }
        }
      }
    }

    .mobile-data {
      display: none;

      .group {
        text-align: center;
        font-size: 24px;
      }

      .coins {
        margin-right: 12px;
        margin-top: 18px;

        img {
          width: 24px;
          height: 24px;
        }

        .coin {
          .text {
            margin-left: 8px;
            font-weight: 600;
            font-size: 20px;
            margin-top: 0;
          }

          &:nth-child(1) {
            margin-right: 36px;

            @media screen and (max-width: 1350px) {
              margin-right: 0;
            }
          }
        }
      }

      @media screen and (max-width: 1100px) {
        display: block;
      }
    }

    @media screen and (max-width: 1350px) {
      width: 100% !important;
      position: relative;
    }
  }

  @media screen and (max-width: 1350px) {
    width: 100% !important;
    min-width: initial;
  }
}

.p-sidebar-header {
  display: none !important;
}

.p-sidebar-content {
  padding: 0 !important;
}
