#root {
    display: flex;
}

.auth-page.container {
    flex: 1;
    background: rgb(237, 241, 247);

    .form {
        background: #fff;
        padding: 96px 110px;
        border-radius: 50px;

        .logo img {
            width: 175px;
            height: 70px;
        }

        @media screen and (max-height: 1000px) {
            padding: 46px 50px;
        }

        @media screen and (max-width: 1000px) {
            padding: 46px 50px;
        }

        @media screen and (max-width: 600px) {
            padding: 46px 70px;
        }

        @media screen and (max-width: 500px) {
            background: transparent;
        }

        .field {
            margin-top: 18px;

            &.first {
                margin-top: 77px;
            }

            .pi {
                font-size: 1.4em;
            }

            @media screen and (max-width: 600px) {
                margin-top: 18px;

                &.first {
                    margin-top: 57px;
                }
            }
        }

        input {
            width: 417px;
            height: 65px;

            @media screen and (max-width: 600px) {
                width: 300px;
            }

            @media screen and (max-width: 500px) {
                width: 250px;
            }
        }

        button {
            margin-top: 62px !important;

            @media screen and (max-width: 600px) {
                margin-top: 42px !important;
            }
        }

        .error-msg {
            margin-top: 24px;
        }
    }
}
