.error-page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    display: flex;
    flex-direction: row;
    padding: 0 60px;

    .text {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-content {
        h1 {
          font-size: 150px;
          color: #3b82f6;
          font-weight: 700;

          @media screen and (max-width: 1000px) {
            font-size: 120px;
          }

          @media screen and (max-width: 500px) {
            font-size: 100px;
          }
        }

        .subtitle {
          font-size: 32px;
          font-weight: 700;

          @media screen and (max-width: 500px) {
            font-size: 24px;
            margin-top: 12px;
          }
        }

        .message {
          margin-top: 20px;
          font-size: 18px;
        }
      }

      .button-container {
        margin-bottom: 48px;

        .logout {
          margin-left: 12px;
        }

        @media screen and (max-width: 1000px) {
          margin-bottom: 0;
          margin-top: 36px;
        }

        @media screen and (max-width: 600px) {
          display: flex;
          flex-direction: column;

          button {
            width: fit-content;
          }

          .logout {
            margin-left: 0;
            margin-top: 12px;
          }
        }
      }
    }

    .img {
      @media screen and (max-width: 1000px) {
        width: 50%;
        height: 50%;
      }

      @media screen and (max-width: 800px) {
        display: none;
      }
    }

    @media screen and (max-width: 500px) {
      padding: 0 18px;
    }
  }
}
