.class {
  cursor: pointer;
  width: 48px;
  height: 48px;
  background: #3b82f6;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  &.disabled {
    cursor: not-allowed;
    background: #89b4fa;
  }
  &.active {
    background: #1d4ed8;
  }
  &.absent {
    background: #e52a23;
    &.active {
      background: #b71f18;
    }
  }
  &.late {
    background: #d97706;
  }
}