header {
  background: #fff;
  color: #495057;
  height: 90px;
  border-radius: 62px;

  .profile {
    height: 100%;
    .avatar {
      margin: 12px;
      position: relative;

      img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        object-fit: cover;
      }

      .config {
        width: 25px;
        height: 25px;
        background: #ced4da;
        border-radius: 50%;
        position: absolute;
        right: 0;
        bottom: 0;

        img {
          width: 14px;
          height: 14px;
        }
      }
    }

    .name {
      .last-name,
      .first-name {
        font-size: 20px;
        margin-left: 12px;
        color: #495057;
      }
    }
  }

  .group {
    text-align: center;
    font-size: 20px;

    @media screen and (max-width: 1350px) {
      display: none;
    }
  }

  .coins {
    margin-right: 12px;

    .coin {
      padding: 8px 12px;
      border: 1px solid #ced4da;
      border-radius: 24px;

      img {
        width: 30px;
        height: 30px;
      }

      .text {
        margin-left: 12px;
        font-weight: 600;
        font-size: 20px;
        margin-top: 0;

        &.none {
          display: none;
        }
      }

      &:nth-child(1) {
        margin-right: 18px;
      }

      @media screen and (max-width: 1350px) {
        &:nth-child(1) {
          margin-right: 0 !important;
        }

        &:nth-child(2) {
          display: none !important;
          margin-left: auto;
        }
      }
    }

    @media screen and (max-width: 1350px) {
      margin-left: auto;
    }
  }

  .btn-container {
    height: 100%;
    display: none;

    @media screen and (max-width: 1350px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    button {
      margin-right: 12px;
    }
  }
}

#overlay_panel {
  .p-overlaypanel-content {
    display: flex;
    flex-direction: column;
    padding: 24px 0;
    max-height: 100%;
    overflow: auto;
    overflow-x: hidden;
  }
}
