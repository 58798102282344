.classType {
    position: relative;
    cursor: pointer;
    border: 1px solid #ced4da;
    border-radius: 10px;
    padding: 14px 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    background: white;
    color: #495057;

    &.active {
        background: #9d6ac5;
        color: white;
    }

    &:disabled {
        cursor: not-allowed;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            background: rgba($color: #cccccc, $alpha: 0.4);
        }
    }
}
