.timetable-page {
  .nearest-lessons {
    .lesson {
      min-width: 500px;

      .title {
        width: fit-content;

        .delimiter {
          height: 100%;
          width: 0px;
          background: #89b4fa;
          border: 2px solid #89b4fa;
          margin: 0 12px;
        }
      }

      &.second .area {
        background: #dce9ff !important;

        .text-white {
          color: #495057 !important;
        }

        small {
          color: #89b4fa;
        }
      }
    }
  }

  .filter {
    .date {
      color: #000 !important;
      font-size: 40px;
      font-weight: 700;
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;

      .buttons {
        margin-left: 0 !important;
        margin-top: 12px;
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  .calendar {
    &-item {
      flex: 0 0 auto;
      width: 14.28%;
      min-width: 280px;
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      &__content {
        height: 100%;
        border-top: 3px solid #495057;
        background: #eaecef;

        .head {
          h1 {
            font-size: 40px;
            font-weight: 700;
            color: #495057;
          }

          .day {
            font-weight: 700;
            font-weight: 700;
            color: #49505730;
          }
        }

        &.active {
          border-top-color: #3b82f6;
          background: #f8f9fb;

          &:hover {
            cursor: pointer;
          }

          .head {
            h1 {
              color: #3b82f6;
            }

            .day {
              color: #3b82f630;
            }
          }

          .body {
            margin: 24px;
            margin-top: 0;
            overflow-y: scroll;

            &::-webkit-scrollbar-track {
              background: #f8f9fb;
            }

            &::-webkit-scrollbar-thumb {
              border-color: #f8f9fb;
            }

            .lesson {
              margin-top: 12px;
              border-bottom: 2px solid #3b82f6;
              padding-bottom: 12px;

              &:first-of-type {
                margin-top: 0;
              }

              &:last-of-type {
                border-bottom: 0;
              }
            }

            @media screen and (max-width: 500px) {
              margin: 12px;
            }
          }
        }

        &.disabled {
          h1 {
            color: #ced4da;
          }

          .day {
            color: #49505730;
          }
        }

        &.current {
          background: #fff;
        }
      }

      @media screen and (max-width: 500px) {
        min-width: 240px;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .card .header .title {
      font-size: 24px !important;
    }
  }
}
