.messageView {
  color: #80888F;
  background: #FFFFFF;
  border-radius: 15px;
  padding: 24px;
  .title {
    color: #495057;
    font-weight: 600;
    font-size: 20px;
  }
  .from {
    color: #495057;
  }
}