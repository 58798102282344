.occupied {
    font-size: 48px;
}

.statisticList {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    overflow-x: scroll;
    overflow-y: hidden;
}

.statisticElement {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    cursor: pointer;

    .circle {
        width: 40px;
        min-height: 40px;
        border-radius: 50%;
        background: #495057;
        font-size: 20px;
        color: #ecf0f8;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 8px 18px 0;
        position: relative;
        z-index: 2;

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    .progress {
        background: #ecf0f8;
        width: 2px;
        border-radius: 50%;
        z-index: 2;

        &.current::after {
            content: '';
            z-index: -1;
            position: absolute;
            width: 48px;
            left: 15px;
            bottom: 22px;
            background: linear-gradient(
                180deg,
                rgba(236, 240, 248, 0) 0%,
                #ecf0f8 100%
            );
            border-radius: 20px;
        }
    }

    .title {
        font-size: 12px;
        margin-top: 12px;
        color: #c1c9d1;
        z-index: 2;
    }

    .point {
        width: 10px;
        height: 10px;
        background: #ecf0f8;
        border-radius: 50%;
        z-index: 2;
        color: #ffffff;
        font-size: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &.colored {
            background: #3b82f6;
            transition: all 0.2s ease-in-out;
        }
    }

    &:hover > .point.colored {
        width: 40px;
        height: 40px;
        font-size: 20px;
    }
}
