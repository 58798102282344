.floating {
  position: fixed !important;
  bottom: 20px;
  right: 20px;
  opacity: 0;

  &.visible {
    opacity: 1;
  }

  &.in {
    animation-duration: 0.2s;
    animation-name: show-btn;
  }

  &.out {
    animation-duration: 0.2s;
    animation-name: hide-btn;
  }
}

@keyframes show-btn {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 20px;
    opacity: 1;
  }
}

@keyframes hide-btn {
  from {
    bottom: 20px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}
