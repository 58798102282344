.profile-page {
  .profile {
    .img {
      position: relative;

      img {
        width: 192px;
        height: 192px;
        border-radius: 50%;
        object-fit: cover;

        @media screen and (max-width: 1500px) {
          width: 140px;
          height: 140px;
        }
      }

      input {
        opacity: 0;
        position: absolute;
        z-index: -1;
      }

      label {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 48px;
        height: 48px;
        background: #3b82f6;
        border-radius: 50%;
        cursor: pointer;

        &.loading {
          cursor: default;
          opacity: 0.6;
        }

        @media screen and (max-width: 1500px) {
          width: 36px;
          height: 36px;
        }
      }
    }

    .fz-40 {
      font-size: 40px;

      @media screen and (max-width: 1500px) {
        font-size: 24px;
        margin-top: 16px !important;
      }
    }

    small {
      color: #80888f;

      @media screen and (max-width: 1500px) {
        margin-top: 8px !important;
      }
    }
  }

  .form {
    @media screen and (max-width: 1500px) {
      margin-top: 48px !important;
    }
  }
}
