.groupHoverCardContent {
    padding: 24px;
    border-radius: 6px;
    background: #FFFFFF;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.3);

    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;

    .groupHoverCardStatus {
        color: #D97706;
    }

    .groupHoverCardName {
        font-size: 16px;
        font-weight: 600;
    }

    &[data-side="top"] {
        animation-name: slideDownAndFade;
    }

    &[data-side="right"] {
        animation-name: slideLeftAndFade;
    }

    &[data-side="bottom"] {
        animation-name: slideUpAndFade;
    }

    &[data-side="left"] {
        animation-name: slideRightAndFade;
    }
}

.groupHoverCardArrow {
    fill: #FFFFFF;
}

.groupHoverCardTrigger {
    color: #495057;
    text-decoration: none;
    cursor: pointer;
}

@keyframes slideUpAndFade {
    0% {
        opacity: 0;
        transform: translateY(2px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideRightAndFade {
    0% {
        opacity: 0;
        transform: translateX(-2px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideDownAndFade {
    0% {
        opacity: 0;
        transform: translateY(-2px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideLeftAndFade {
    0% {
        opacity: 0;
        transform: translateX(2px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
