.tableHeader {
    padding: 10px !important;

    span {
        font-size: 12px !important;
        color: #C1C9D1 !important;
    }
}

.fullnameHeader {
    align-items: start !important;
}

.groupStudentsDatatable {
    .p-datatable-thead {
        background: #FFF;
    }

    .p-datatable-body {
        tr {
            height: 40px;
        }
    }
}

.statusHeader {
    & .p-column-header-content {
        align-items: end !important;
    }

    &[aria-sort=none] {
        svg {
            color: #C1C9D1 !important;
        }
    }

    & svg {
        width: 10px !important;
        height: 10px !important;
    }
}

.groupStudentsDatatableRow {
    height: 40px;

    & > td {
        padding: 0 10px !important;
    }
}