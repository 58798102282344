.filter {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    background: #ffffff;
    border-radius: 12px;
    padding: 24px;
    padding-top: calc(24px - 0.5rem);
    margin-bottom: 24px;

    .loading {
        opacity: 0.6;
    }

    .search::placeholder {
        color: #6c757d;
        opacity: 1;
    }
}
