.lesson {
    background: #3b82f6;
    border-radius: 10px;
    color: #ffffff;
    font-weight: 600;
    font-size: 24px;
    flex-direction: column;
    padding: 24px 24px 37px !important;
    gap: 20px;
    display: flex;
    word-break: break-word;
    cursor: pointer;

    &.activeLesson {
        background: darken(#3b82f6, 20%);
    }
    .dates {
        font-weight: 600;
        display: flex;
        align-items: stretch;
        gap: 12px;
    }
    .row {
        display: flex;
        gap: 6px;
        flex-direction: column;
    }
    .rowTitle {
        font-size: 16px;
        color: #89b4fa;
    }
    .delimiter {
        width: 3px;
        background: #89b4fa;
    }
    &.disabled {
        background: #dce9ff;
        color: #495057;
        touch-action: none;
        cursor: not-allowed;
        .delimiter {
            background: #89b4fa;
        }
    }
}
